<template>
  <div>
    <a
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      :title="title"
      @click="fnShowModal()"
      ><i class="fas fa-plus mr-2"></i>{{ title }}
    </a>

    <b-modal ref="userModal" hide-footer :title="title" size="xl">
      <div class="d-block">
        <form v-on:submit.prevent="fnNewUser">
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>

          <div
            class="row"
            v-if="loginUserObj.role_id == 1 || loginUserObj.role_id == 2"
          >
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <select
                class="form-control form-group"
                v-model="userData.role_id"
              >
                <option value="1">Admins</option>
                <option value="2">Staff</option>
                <option value="10">Not yet decide</option>
              </select>
              <label class="floatingLabel" for="firstName">User type</label>
              <div class="invalid-feedback" v-if="errors.role_id">
                {{ errors.role_id[0] }}
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div
                class="form-group"
                v-if="userData.role_id == 4 || userData.role_id == 6"
              >
                <input
                  class="form-control"
                  type="text"
                  id="business_name"
                  autocomplete="off_business_name"
                  placeholder=" "
                  v-model="userData.business_name"
                />
                <label class="floatingLabel" for="firstName"
                  >Business name</label
                >
                <div class="invalid-feedback" v-if="errors.business_name">
                  {{ errors.business_name[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div
                class="form-group"
                v-if="userData.role_id == 4 || userData.role_id == 6"
              >
                <input
                  class="form-control"
                  type="text"
                  id="position_title"
                  autocomplete="off_position_title"
                  placeholder=" "
                  v-model="userData.position_title"
                />
                <label class="floatingLabel" for="position_title"
                  >Position title</label
                >
                <div class="invalid-feedback" v-if="errors.position_title">
                  {{ errors.position_title[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3"
              v-if="userType == 'corporate_customer'"
            >
              <div class="form-group">
                <select class="form-control" v-model="userData.status">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                <label class="floatingLabel" for="firstName"
                  >Create account for applicant</label
                >
                <div class="invalid-feedback" v-if="errors.status">
                  {{ errors.status[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="userData.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
                <div class="invalid-feedback" v-if="errors.first_name">
                  {{ errors.first_name[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="userData.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="userData.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="userType != 'corporate_customer'">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="userData.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <select class="form-control" v-model="userData.gender_code">
                  <option
                    :value="item.value"
                    v-for="item in optionsGender"
                    :key="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <label class="floatingLabel vueBootSelect" for="position_title"
                  >Gender</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group multiselect">
                <b-form-select
                  value-field="name"
                  text-field="name"
                  :options="getSettingAll.country"
                  v-model="userData.country_of_birth"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >Country of birth</label
                >
                <div class="invalid-feedback" v-if="errors.country_of_birth">
                  {{ errors.country_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="email"
                  autocomplete="off_email"
                  placeholder=" "
                  v-model="userData.email"
                />
                <label class="floatingLabel" for="email">Email</label>
                <div class="invalid-feedback" v-if="errors.email">
                  {{ errors.email[0] }}
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <!-- Int Tell Country -->
              <div class="form-group multiselect">
                <b-form-select
                  value-field="dial_code"
                  text-field="name"
                  placeholder=" "
                  :options="optionsIntTel"
                  v-model="userData.int_tel_code"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >Country<span class="fs-700 text-danger">
                    (Mobile number)</span
                  ></label
                >
                <div class="invalid-feedback" v-if="errors.int_tel_code">
                  {{ errors.int_tel_code[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder=" "
                  v-model="userData.mobile_no"
                />
                <label class="floatingLabel"
                  >Mobile number
                  <span class="fs-700 text-danger"
                    >(Without country code)</span
                  ></label
                >
                <div class="invalid-feedback" v-if="errors.mobile_no">
                  {{ errors.mobile_no[0] }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="row mt-5"
            v-if="
              loginUserObj.role_id != 4 &&
              loginUserObj.role_id != 5 &&
              loginUserObj.role_id != 1 &&
              loginUserObj.role_id != 2
            "
          >
            <div class="col-12">
              <p class="text-info">Current address details</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="unit_no"
                  autocomplete="off_unit_no"
                  placeholder=" "
                  v-model="userData.unit_no"
                />
                <label class="floatingLabel" for="unit_no">Unit number</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street_no"
                  autocomplete="off_street_no"
                  placeholder=" "
                  v-model="userData.street_no"
                />
                <label class="floatingLabel" for="street_no"
                  >Street number</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street"
                  autocomplete="off_street"
                  placeholder=" "
                  v-model="userData.street"
                />
                <label class="floatingLabel" for="street">Street</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="suburb"
                  autocomplete="off_suburb"
                  placeholder=" "
                  v-model="userData.suburb"
                />
                <label class="floatingLabel" for="suburb">Suburb</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <b-form-select
                v-model="userData.state_code"
                :options="optionsState"
              >
              </b-form-select>
              <label class="floatingLabel vueBootSelect" for="state"
                >State</label
              >
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="postCode"
                  autocomplete="off_postCode"
                  placeholder=" "
                  v-model="userData.post_code"
                />
                <label class="floatingLabel" for="postCode">Post code</label>
              </div>
            </div>
          </div>
          <hr />
          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">
              Save <b-spinner small v-if="loading" class="mr-2"></b-spinner>
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../../services/message.service";
import * as userAdminService from "../../../services/userAdmin.service";
import * as authService from "../../../services/auth.service";
import {
  intTel,
  optionsGender,
  optionsState,
} from "../../../services/data.service";
export default {
  name: "ModalUserNew",
  components: {},
  props: ["userType", "title"],
  data() {
    return {
      errors: {},
      loginUserObj: {},
      loading: false,
      optionsIntTel: intTel,
      userData: {
        gender_code: "M",
        business_name: "",
        position_title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        role_id: "",
        parent_id: "",
        date_of_birth: "",
        country_of_birth: "AUS",
        email: "",
        mobile_no: "",
        int_tel_code: "",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        status: "",
      },
      optionsGender: optionsGender,
      optionsState: optionsState,
    };
  },
  computed: {
    ...mapGetters([
      "getSettingAll",
      "getUserPaginate",
      "getUserCorporatePaginate",
    ]),
  },
  methods: {
    ...mapActions(["fetchUserListPaginate", "fetchUserCorporateListPaginate"]),
    fnShowModal() {
      this.loginUserObj = authService.getUserFromToken();
      this.showModal();
    },
    hideModal() {
      this.$refs.userModal.hide();
    },
    showModal() {
      this.$refs.userModal.show();
    },

    fnNewUser: async function () {
      // Admin / Admin staff
      if (this.loginUserObj.role_id == 1 && this.userType == "admin") {
        this.userData.parent_id = this.loginUserObj.parent_id;
        this.userData.status = 1;
      }

      // Partner Staff and parent id should be partner id
      if (this.loginUserObj.role_id == 6 && this.userType == "partner_staff") {
        this.userData.role_id = 7;
        this.userData.parent_id = this.loginUserObj.parent_id;
        this.userData.status = 1;
      }
      // Partner Customer and parent id should be partner id created by Partner
      // Partner Customer and parent id should be partner id created by Partner Staff
      if (
        (this.loginUserObj.role_id == 6 || this.loginUserObj.role_id == 7) &&
        this.userType == "partner_customer"
      ) {
        this.userData.role_id = 8;
        this.userData.parent_id = this.loginUserObj.parent_id;
      }

      // Corporate Staff and parent_id should be corporate id
      if (
        this.loginUserObj.role_id == 4 &&
        this.userType == "corporate_staff"
      ) {
        this.userData.role_id = 5;
        this.userData.parent_id = this.loginUserObj.parent_id;
        this.userData.status = 1;
      }
      // Corporate Customer and parent_id should be corporate id created by corporate
      // Corporate Customer and parent_id should be corporate id created by corporate Staff
      if (
        (this.loginUserObj.role_id == 4 || this.loginUserObj.role_id == 5) &&
        this.userType == "corporate_customer"
      ) {
        this.userData.role_id = 9;
        this.userData.parent_id = this.loginUserObj.parent_id;
      }

      let formData = new FormData();
      formData.append("business_name", this.userData.business_name);
      formData.append("position_title", this.userData.position_title);
      formData.append("first_name", this.userData.first_name);
      formData.append("middle_name", this.userData.middle_name);
      formData.append("last_name", this.userData.last_name);
      formData.append("role_id", this.userData.role_id);
      formData.append("parent_id", this.userData.parent_id);
      formData.append("gender_code", this.userData.gender_code);
      formData.append("date_of_birth", this.userData.date_of_birth);
      formData.append("country_of_birth", this.userData.country_of_birth);
      formData.append("email", this.userData.email);
      formData.append("int_tel_code", this.userData.int_tel_code);
      formData.append("mobile_no", this.userData.mobile_no);
      formData.append("unit_no", this.userData.unit_no);
      formData.append("street_no", this.userData.street_no);
      formData.append("street", this.userData.street);
      formData.append("suburb", this.userData.suburb);
      formData.append("state_code", this.userData.state_code);
      formData.append("post_code", this.userData.post_code);
      formData.append("status", this.userData.status);
      this.errors = {};

      try {
        this.loading = true;
        await userAdminService.add(formData);

        if (
          this.userType == "corporate_customer" ||
          this.userType == "corporate_staff"
        ) {
          this.fetchUserCorporateListPaginate(this.getUserCorporatePaginate);
        } else if (
          this.userType == "partner_customer" ||
          this.userType == "partner_staff"
        ) {
          this.fetchUserCorporateListPaginate(this.getUserCorporatePaginate);
        } else {
          this.fetchUserListPaginate(this.getUserPaginate);
        }

        messageService.fnSweetAlertSuccessToast("User - Successfully created");

        this.loading = false;

        this.userData = {
          business_name: "",
          position_title: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          gender_code: "M",
          date_of_birth: "",
          country_of_birth: "AUS",
          email: "",
          mobile_no: "",
          int_tel_code: "",
          unit_no: "",
          street_no: "",
          street: "",
          suburb: "",
          state_code: "",
          post_code: "",
          status: false,
        };

        this.hideModal();
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "User - update",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "User - update",
              "Something wrong"
            );
            break;
        }

        this.loading = false;
      }
    },
  },
};
</script>
